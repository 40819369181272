import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Designing Future Democracies`}</h1>
    <p>{`The Computational Democracy Project designs, engineers and maintains `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{`, an open source, real-time system for gathering, analyzing and understanding what large groups of people think in their own words, enabled by advanced statistics and machine learning.`}</p>
    <p>{``}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` and implementations of the technology have been widely covered in the popular press — including articles from The New York Times, The Guardian, The Economist and MIT Tech Review — as well as in documentaries from the BBC and books from Penguin. A full catalogue of press coverage can be found in `}<a parentName="p" {...{
        "href": "/media-coverage",
        "title": "media coverage"
      }}>{`media coverage`}</a>{`.`}</p>
    <p>{`CompDem hosts `}<a parentName="p" {...{
        "href": "https://pol.is/home"
      }}>{`https://pol.is/home`}</a>{`, and provides support and `}<a parentName="p" {...{
        "href": "/services",
        "title": "services"
      }}>{`services`}</a>{` to communities of journalists, academic researchers and public servants innovating future democratic systems.`}</p>
    <p>{`CompDem also maintains an extensive and growing `}<a parentName="p" {...{
        "href": "/knowledge-base"
      }}>{`knowledge base`}</a>{` for those implementing `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{`, as well as an `}<a parentName="p" {...{
        "href": "https://github.com/compdemocracy/openData/"
      }}>{`open data repository`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/compdemocracy/notebooks"
      }}>{`open source Jupyter notebooks`}</a>{` for academics and data scientists participating in developing methods.`}</p>
    <h3>{`Contact`}</h3>
    <p><a parentName="p" {...{
        "href": "mailto:hello@compdemocracy.org"
      }}>{`hello@compdemocracy.org`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      